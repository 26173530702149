import React, { Component } from "react";
import increaseArrowImg from "../../images/MyRewards/increaseArrowImg.png"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class MyRewardCarousel extends Component {
    constructor() {
        super();
        this.state = {
            data:[
                {image: increaseArrowImg, text:"500+ new institutes already joined"},
                {image: increaseArrowImg, text:"10+ referrals on average by each tutor"},
                {image: increaseArrowImg, text:"150K+ earned by all coaching institutes"},
            ]
        };
    }

    render() {
        const settings = {
			// dots: true,
			// infinite: true,
			// slidesToShow: 1,
			// slidesToScroll: 1,
			// autoplay: true,
			// speed: 1000,
			// autoplaySpeed: 2000,
			// cssEase: 'linear',
			// pauseOnHover: true,
            // arrows: false,
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            autoplay: true,
		};
        let {data} = this.state
        return (
            <div className="MyRewardCarousel">
            <Slider {...settings} style={{width:"99.5%"}} >
           {data.map((data, index)=>{
               return <div className="MyRewardCarousel-Item" key={index} >
               <img src={data.image} alt="" />
               <div className="MyRewardCarousel-Item-Text">{data.text}</div>
           </div>
           })}
                        
           
            </Slider>
                
            </div>
        );
    }
}

export default MyRewardCarousel;
