import React, {Component} from 'react';
import logo from "../../images/TnC/logo.png"
import arrow from "../../images/TnC/arrow.svg"
import "./TnC.css"
import { withRouter } from 'react-router-dom';

class TnC extends Component {

    componentDidMount(){
        window.scrollTo(0,0)
    }

    render() {
        return (
            <div className="terms-condition">
                <div className="terms-condition-header">
                    <img src={arrow} alt="" onClick={()=>{this.props.history.push("/")}} />
                    <img src={logo} alt="" className="logo-image" />
                </div>
                <div class="terms-condition-content">
                    <p class="terms-condition-firstPara">At Classplus, we believe in growing together. We understand how
                    customers are the strongest pillars
                    who can take a company forward. To spread the goodness of technology-powered coaching
                    institutes and the experience they are able to provide to their students and parents by saving time
                    on daily tasks, we are introducing <strong> Partner Program </strong>for our customers so that we all
                    grow together.</p>

                    <p class="terms-condition-question">Q. What is a verified referral?</p>

                    <p>A. A referral is when you refer a real coaching institute to our team as a prospect for our services.</p>

                    <p class="terms-condition-question">Q. What is a successful referral?</p>
                    
                    <p>A. When the referral you share with the team becomes our customer by subscribing to any of our
                    product packages, it is termed as a successful referral for you.</p>

                    <div class="terms-condition-secondPara" style={{borderBottom:"none"}}>
                        <h3 style={{textAlign:"center", fontSize:"15px", fontWeight:"bold", lineHeight:"19px", margin:"14px 0"}}>Referral Offer New T&C</h3>
                        <p>Offer ends on 10th January, 2021</p>
                        <p>On 2 Successful Referrals, you will win ₹3000 Amazon Voucher</p>
                        <p>On 4 Successful Referrals, you will win a Wireless Graphic Pen</p>
                        <p>On 6 Successful Referrals, you will win a Mi TV 4A</p>
                        <p>The partner who completes 20 Verified Referrals first, will win a Sourav Ganguly signed bat</p>
                    </div>

                    <div class="terms-condition-secondPara" style={{borderBottom:"none"}}>
                        <h3 style={{textAlign:"center", fontSize:"15px", fontWeight:"bold", lineHeight:"19px", margin:"14px 0"}}>Special Reward</h3>
                        <p>On 25th December, the partner who refers 20 teachers, will win a personalised T-shirt for their institute signed by Sourav Ganguly.</p>
                        <br/>

                        <p>* The offer is only valid on verified referrals.</p>
                        <p>* Only the referrals submitted on 25th December, 2020 will be accounted for in this offer.</p>
                        <p>* This offer is on first come first serve basis. Only the partner who fulfills the criteria first will be awarded with the T-shirt.</p>
                        <p>* Classplus will provide you with a T-shirt digitally signed by Sourav Ganguly and your logo on the front. The t-shirt will not be physically signed by Sourav Ganguly</p>
                    </div>

                    <p class="terms-condition-secondPara">
                        We like to share incentives that can help you in your product or
                        commercially whenever you share a
                        referral with us, and higher ones when it ends up as a successful referral.<br></br>

                        <p style={{margin:"16px 0"}}>On every <strong>verified referral</strong>, you can choose between <strong>100 Live class hours or
                        5,000 Classplus coins</strong><br></br></p>

                        <p style={{margin:"16px 0"}}>On every <strong>successful referral</strong>, you can choose between <strong>1000 Live class hours or
                        60,000 Classplus coins.</strong><br></br></p>

                        <strong>Future earnings:</strong><br></br>

                        <div style={{marginBottom:"16px"}}>Any content that is sold on your referee app, You will receive <strong>50% of the revenue</strong>
                         {" "}earned by Classplus.
                        <br></br></div>

                        The validity of the Partner Program will be until the end of your referral’s current subscription,
                        provided that you have an active Classplus subscription
                    </p>

                    <p class="terms-condition-question">Q. How do you redeem coins into benefits?</p>
                    <p> A. You can redeem your coins for buying free SMS, additional features on your app or at the time of your
                        renewal for discount</p>
                    <p>Please use the table below for understanding the benefits of coins.</p>

                    <table>
                        <tr>
                            <th>Number of coins</th>
                            <th>What you can get</th>
                        </tr>
                        <tr>
                            <td>1,20,000 </td>
                            <td>10,000 SMS</td>
                        </tr>
                        <tr>
                            <td>3,50,000</td>
                            <td>1 year of Video Portal</td>
                        </tr>
                        <tr>
                            <td>5,00,000</td>
                            <td>1 year of My institute app(iOS)</td>
                        </tr>
                        <tr>
                            <td>10,00,000</td>
                            <td>1 year of Online Test Portal</td>
                        </tr>
                    </table>
                    
                    <p>You can also redeem your coins for getting a discount on your renewal price. The conversion for
                coins to discount is 10,000 coins = INR 100, up to a maximum of 25% of your renewal amount value.</p>
                </div>
                
            </div>
        )
    }
}

export default withRouter(TnC);