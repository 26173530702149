import React, { Component } from 'react';
import Modal from "../../UI/Modal/Modal";
import FormatModal from "../../UI/FormatModal/FormatModal";
import coinsImg from "../../images/Modal/coinsImg.png"
import liveHoursImg from "../../images/Modal/liveHoursImg.png"
import revenueImg from "../../images/Modal/revenueImg.png"
import { baseUrl } from "../../config"

class YourRewardsModal extends Component {
    constructor() {
        super();
        this.state = {
            rewardsModalData:[
                {image: liveHoursImg, text:"Live Hours", value:"100 Hours"},
                {image: coinsImg, text:"Coins", value:"10,000 Coins"},
                {image: revenueImg, text:"Your Revenue", value:"₹0"},
            ],
            myRewardApiResponse:{}
        };
    }

    componentDidMount(){
        let id = this.props && this.props.id;
        this.myRewardsApiCall(id)
    }

    getQuery() {
        let b = window.location.search
            .slice(1)
            .split("&")
            .map(qStr => qStr.split("="))
            .reduce((acc, inc) => {
                acc[inc[0]] = inc[1];
                return acc;
            }, {});
        // console.log(b)
        return b;
    }

    myRewardsApiCall = (id)=>{
        // console.log(id)
        fetch(`${baseUrl}/v2/partner/user/${id}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                "x-access-token": this.getQuery()["token"] || "eyJhbGciOiJIUzM4NCIsInR5cCI6IkpXVCJ9.eyJpZCI6Mjg5OTk0LCJvcmdJZCI6MTcwLCJuYW1lIjoiR3VuamFuIiwiZW1haWwiOiJndW5qYW5AY2xhc3NwbHVzLmNvIiwibW9iaWxlIjoiOTE5NzExMTUzMDc5IiwidHlwZSI6MywiaWF0IjoxNjA4NjI4NjE4LCJleHAiOjE2MDkyMzM0MTh9.4prlPW0F37goSDfAK9rlCHQQLAQzATpamSoEBctzzCMgYvhlqLmTaJRtO-uBdunb"
            },
        }).then(res => {
            return res.json()
        })
            .then(data => {
                if (data.status == "success") {
                    this.setState({myRewardApiResponse: data.data}, ()=>{
                        // console.log(this.state.myRewardApiResponse)
                    })
                    // modalRewards = data.data
                    // document.querySelector(".modal-container-rewards").classList.remove("hide")
                    // integrateModalRewardsFunct()
                } else {
                    // return toastr.warning(`${data.message} ${data.data.message ? `: ${data.data.message}` : ''}`,
                    //     `${data.status}`)
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    render() {
        let { isRewardsModal } = this.props;
        let { rewardsModalData, myRewardApiResponse} = this.state
        // console.log(myRewardApiResponse)
        return (
            <Modal toggle={this.props.handleRewardsModal} hidden={!isRewardsModal} style={{width: window.screen.width < 700 ? "80%" : "30%", height:"255px", left:window.screen.width < 700 ? "10%" : "35%", top:"25%", borderRadius:"4px"}} >
                <FormatModal>
                    <div className="YourRewardsModal">
                        <div className="YourRewardsModal-Header">
                            <div className="YourRewardsModal-Header-Heading">Your Rewards</div>
                        </div>
                        <div className="YourRewardsModal-Container">
                       
                            <div className="YourRewardsModal-Container-Item" >
                                        <div className="YourRewardsModal-Container-Item-TextContent">
                                            <img src={liveHoursImg} alt="" />
                                            <div className="YourRewardsModal-Container-Item-TextContent-Text">Live Hours</div>
                                        </div>
                                        <div className="YourRewardsModal-Container-Item-Value">{myRewardApiResponse && myRewardApiResponse.liveHours} Hours</div>
                            </div>

                            <div className="YourRewardsModal-Container-Item" >
                                        <div className="YourRewardsModal-Container-Item-TextContent">
                                            <img src={coinsImg} alt="" />
                                            <div className="YourRewardsModal-Container-Item-TextContent-Text">Coins</div>
                                        </div>
                                        <div className="YourRewardsModal-Container-Item-Value">{myRewardApiResponse && myRewardApiResponse.coins} Coins</div>
                            </div>

                            <div className="YourRewardsModal-Container-Item" >
                                        <div className="YourRewardsModal-Container-Item-TextContent">
                                            <img src={revenueImg} alt="" />
                                            <div className="YourRewardsModal-Container-Item-TextContent-Text">Your Revenue</div>
                                        </div>
                                        <div className="YourRewardsModal-Container-Item-Value">₹ {myRewardApiResponse && myRewardApiResponse.revenue}</div>
                            </div>
                     
                           
                        </div>
                    </div>
                </FormatModal>
            </Modal>

        );
    }
}
export default YourRewardsModal;
