import React, { Component } from "react";
class YoutubeVideo extends Component {
    constructor() {
        super();
        this.state = {

        };
    }

    render() {
        return (
            <div className="YoutubeVideo">
                <div className="YoutubeVideo-Heading">Sourav’s special message for you!</div>
                <div className="YoutubeVideo-iframe">
                    <iframe src="https://www.youtube.com/embed/BbEdxaqThw0" className="YoutubeVideo-iframe-video" />
                </div>
            </div>
        );
    }
}

export default YoutubeVideo;
