import React, { Component } from "react";
import Home from "./components/Home/Home";
import MyRewards from "./components/MyRewards/MyRewards"
import "./App.scss";
import "react-toastify/dist/ReactToastify.css";
import { baseUrl } from "./config"
import TnC from "./components/TnC/TnC"

import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

class App extends Component {
  constructor() {
    super();
    this.state = {
      infoApi:{}
    };
  }
  
  componentDidMount(){
    this.getInfoApi()
  }

  getQuery() {
    let b = window.location.search
        .slice(1)
        .split("&")
        .map(qStr => qStr.split("="))
        .reduce((acc, inc) => {
            acc[inc[0]] = inc[1];
            return acc;
        }, {});
    // console.log(b)
    return b;
}

  getInfoApi = ()=>{
    fetch(`${baseUrl}/v2/partner/info`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": this.getQuery()["token"] || "eyJhbGciOiJIUzM4NCIsInR5cCI6IkpXVCJ9.eyJpZCI6Mjg5OTk0LCJvcmdJZCI6MTcwLCJuYW1lIjoiR3VuamFuIiwiZW1haWwiOiJndW5qYW5AY2xhc3NwbHVzLmNvIiwibW9iaWxlIjoiOTE5NzExMTUzMDc5IiwidHlwZSI6MywiaWF0IjoxNjA4NjI4NjE4LCJleHAiOjE2MDkyMzM0MTh9.4prlPW0F37goSDfAK9rlCHQQLAQzATpamSoEBctzzCMgYvhlqLmTaJRtO-uBdunb"
      },
    })
      .then((res) => res.json())
      .then((res) => {
        this.setState({ infoApi:res.data },()=>console.log(this.state.apiData));
      })
      .catch((err) => console.log(err.message));

  }

  render() {
    let {infoApi} = this.state
    return (
      <div className="App">
        <BrowserRouter>
          <Switch>
            <Route exact path="/" render={()=> <Home infoApiResponse={infoApi} />} />
            <Route exact path="/MyRewards" component={MyRewards} />
            <Route exact path="/TnC" component={TnC} />
            <Route render={() => <Redirect to="/" />} />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
