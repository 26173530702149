import React, { Component } from 'react';
import Modal from "../../UI/Modal/Modal";
import FormatModal from "../../UI/FormatModal/FormatModal";
import successModalImg from "../../images/Modal/successModalImg.gif"
import whatsappIcon from "../../images/Modal/whatsappIcon.png"

class ReferSuccessModal extends Component {
    constructor() {
        super();
        this.state = {
           
        };
    }

    updateFriend = () => {
        const {whatsAppUrl} = this.props
        window.location.href = atob(whatsAppUrl)
    }

    render() {
        let { isReferSuccessModal, name } = this.props;
        return (
            <Modal toggle={this.props.handleReferSuccessModal} hidden={!isReferSuccessModal} >
                <FormatModal>
                    <div className="ReferSuccessModal">
                        <div className="ReferSuccessModal-Image">
                            <img src={successModalImg} alt="" />
                        </div>
                        <div className="ReferSuccessModal-Heading">Awesome!</div>
                        <div className="ReferSuccessModal-Row1">Thank you for participating in Classplus Partner Program. We will credit the benefits once your referral is verified.</div>
                        <div className="ReferSuccessModal-Row2">Say Hi to {`${name}`} and introduce him to Classplus</div>
                        <div className="ReferSuccessModal-Footer">
                            <button className="ReferSuccessModal-Footer-Btn" onClick={this.updateFriend}>
                                <img src={whatsappIcon} alt="" />
                                <label>Update Your Friend</label>
                            </button>
                        </div>
                    </div>
                </FormatModal>
            </Modal>

        );
    }
}
export default ReferSuccessModal;
