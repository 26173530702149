import React, { Component } from "react";
import referWinSantaImg from "../../images/Home/referWinSantaImg.png"
import giftFriendImg from "../../images/Home/giftFriendImg.png"
import amazonVoucher from "../../images/Home/amazonVoucher.png"
import rewardLoveImg from "../../images/Home/rewardLoveImg.png"
import sgSignedBat from "../../images/Home/sgSignedBat.png"
import specialAwardImg from "../../images/Home/specialAwardImg.png"
import wirelessPen from "../../images/Home/wirelessPen.png"
import MiTv from "../../images/Home/MiTv.png"
import RewardCanWin from "../ModalComponents/RewardCanWin"
import ReferModal from "../ModalComponents/ReferModal"
class ReferWinReward extends Component {
    constructor() {
        super();
        this.state = {
            middleSectionDataTop: [
                { image: MiTv, text: "Mi TV 4A" },
                { image: wirelessPen, text: "Wireless Graphic Pen" },
                { image: amazonVoucher, text: "Amazon Vouchers" },
            ],
            middleSectionDataBottom: [
                { image: sgSignedBat, text: "Ganguly signed bat" },
                { image: specialAwardImg, text: "Signed T-shirt by Dada" }
            ],
            middleSectionDataTopNew: [
                { image: MiTv, text: "Mi TV 4A" },
                { image: wirelessPen, text: "Wireless Graphic Pen" },
                { image: sgSignedBat, text: "Ganguly signed bat" },
                { image: amazonVoucher, text: "Amazon Vouchers" },
            ],
            isKnowMoreBtn:false,
            isCloseKnowMoreModal: false,
            isReferModal:false,
        };
    }

    handlleReferModal = ()=>{
        this.setState({isReferModal:false})
    }

    handleKnowMoreBtn = () => {
        this.setState({ isKnowMoreBtn: false });
    };
    
    closeKnowMoreModal = (isCloseModal) => {
    // let { isCloseFormModal } = this.state
    this.setState({ isCloseKnowMoreModal: isCloseModal })
    }

    render() {
        let { middleSectionDataTop, middleSectionDataTopNew, middleSectionDataBottom, isKnowMoreBtn, isCloseKnowMoreModal, isReferModal } = this.state
        return (
            <div className="ReferWinReward">
                <div className="ReferWinReward-TopCard">
                    <img src={referWinSantaImg} alt="" />
                    <div className="ReferWinReward-TopCard-Contents">
                        <div className="ReferWinReward-TopCard-Contents-Heading">Refer & win rewards from Dada</div>
                        <div className="ReferWinReward-TopCard-Contents-SubText">Win exciting rewards from our Santa by helping fellow teachers take their institute online.</div>
                        <button className="ReferWinReward-TopCard-Contents-Button" onClick={()=>{this.setState({isReferModal:true})}}>Refer Now</button>
                    </div>
                </div>
                <div className="ReferWinReward-MiddleCard">
                    <div className="ReferWinReward-MiddleCard-TopSection">
                        <div className="ReferWinReward-MiddleCard-TopSection-TextContent">
                            <div className="ReferWinReward-MiddleCard-TopSection-TextContent-Heading">Rewards that you’ll love</div>
                            <div className="ReferWinReward-MiddleCard-TopSection-TextContent-Text">Plenty of rewards to be won throughout the week. Refer & win!</div>
                            <button className="ReferWinReward-MiddleCard-TopSection-TextContent-Button" onClick={()=>{this.setState({isKnowMoreBtn:true, isCloseKnowMoreModal:false})}}>Know more</button>
                        </div>
                        <img src={rewardLoveImg} alt="" />
                    </div>
                    
                    {/* After special award is over : comment below two div */}

                    <div className="ReferWinReward-MiddleCard-MiddleSection">
                        {middleSectionDataTop.map((data, index) => {
                            return <div className="ReferWinReward-MiddleCard-MiddleSection-Item" key={index}>
                                <img src={data.image} alt="" />
                                <div className="ReferWinReward-MiddleCard-MiddleSection-Item-Text">{data.text}</div>
                            </div>
                        })}
                    </div>
                    <div className="ReferWinReward-MiddleCard-BottomSection">
                        {middleSectionDataBottom.map((data, index) => {
                            return <div className="ReferWinReward-MiddleCard-BottomSection-Item" key={index}>
                                <img src={data.image} alt="" />
                                <div className="ReferWinReward-MiddleCard-BottomSection-Item-Text">{data.text}</div>
                            </div>
                        })}
                    </div>

                    {/* After special award is over : uncomment below div */}

                    {/* <div className="ReferWinReward-MiddleCard-MiddleSectionNew">
                        {middleSectionDataTopNew.map((data, index) => {
                            return <div className="ReferWinReward-MiddleCard-MiddleSectionNew-Item" key={index}>
                                <img src={data.image} alt="" />
                                <div className="ReferWinReward-MiddleCard-MiddleSectionNew-Item-Text">{data.text}</div>
                            </div>
                        })}
                    </div> */}
                </div>
                <div className="ReferWinReward-BottomCard">
                    <div className="ReferWinReward-BottomCard-TextContent">
                        <div className="ReferWinReward-BottomCard-TextContent-Heading">There’s something for your friend too!</div>
                        <div className="ReferWinReward-BottomCard-TextContent-Text">Your friend also gets an instant discount of ₹ 1,000 on MRP</div>
                        <button className="ReferWinReward-BottomCard-TextContent-Button" onClick={()=>{this.setState({isReferModal:true})}}>Refer a Friend</button>
                    </div>
                    <img src={giftFriendImg} alt="" />
                </div>
                <RewardCanWin
                isKnowMoreBtn={isKnowMoreBtn}
                handleKnowMoreBtn={this.handleKnowMoreBtn}
                isCloseKnowMoreModal={isCloseKnowMoreModal}
                closeKnowMoreModal={this.closeKnowMoreModal}
                />
                <ReferModal
                isReferModal={isReferModal}
                handlleReferModal={this.handlleReferModal}
                />
            </div>
        );
    }
}

export default ReferWinReward;
