import React, {Component} from 'react';
import "../scss/components/_videoModal.scss";
import close from "../images/close.png"

class VideoModal extends Component {
    render() {
        const {data,name} = this.props
        return (
            <div className="VideoModal">
                <div className="VideoModal__Container">
                    <div className="VideoModal__Header">
                    <label>{name}</label>
                    <img onClick={this.props.openVideo} src={close} />
                    </div>
                    <div className="VideoModal__Body">
                        <iframe className="VideoModal__Body--Iframe" width="420" height="315"
                            src={`https://www.youtube.com/embed/${data}?controls=1`}>
                        </iframe>
                    </div>
                </div>
            </div>
        )
    }
}

export default VideoModal;