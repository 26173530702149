import React, { Component } from 'react';
import Modal from "../../UI/Modal/Modal";
import FormatModal from "../../UI/FormatModal/FormatModal";
import amazonVoucher from "../../images/Modal/amazonVoucher.png"
import drawingPen from "../../images/Modal/drawingPen.png"
import MiTV from "../../images/Modal/MiTV.png"
import sgBat from "../../images/Modal/sgBat.png"
import specialAward from "../../images/Modal/specialAward.png"
import handShake from "../../images/Modal/handShake.png"
import loudSpeaker from "../../images/Modal/loudSpeaker.png"
import envelope from "../../images/Modal/envelope.png"
import achievementGift from "../../images/Modal/achievementGift.gif"
import modalCross from "../../images/Modal/modalCross.png"
import { withRouter } from 'react-router-dom';

class RewardCanWin extends Component {
    constructor() {
        super();
        this.state = {
            modalData:[
                {image:MiTV, row1:"Win on 6 successful* referrals", row2:"Mi TV 4a pro", row3:"X/6 Referrals Done", bgColor:"#E7EFCC", btnColor:"#D9E7D2"},
                {image:drawingPen, row1:"Win on 4 successful* referrals", row2:"Wireless drawing pen", row3:"X/6 Referrals Done", bgColor:"#FFECC4", btnColor:"#FFE6AE"},
                {image:amazonVoucher, row1:"Win on 2 successful* referrals", row2:"₹ 3000 Amazon voucher", row3:"Criteria Met", bgColor:"#F8D7CE", btnColor:"#FFCDC0" },
                {image:sgBat, row1:"Win on 20 verified referrals", row2:"Ganguly signed bat", row3:"Need X referrals", bgColor:"#FFBABD", btnColor:"#FFB7C0"},
                {image:specialAward, row1:"Win on 20 verified referrals in a day", row2:"Your institute’s personalised T-shirt signed by Dada", row3:"stay tuned", bgColor:"#FBE2AD", btnColor:"#FFE1A1"},
            ],
            howItWorksData:[
                {image:loudSpeaker, text:"Share the details of your friends coaching with Classplus using the Join Partner Program button below."},
                {image:envelope, text:"Improve your chances of winning the rewards by updating your friend that you have referred him to Classplus using the Update your Friend button."},
                {image:handShake, text:"Your referred teacher signs up with Classplus to launch their coaching app."},
                {image:achievementGift, text:"Sit back, relax and wait for the prizes to land on your doorstep."},
            ]
        };
    }

    render() {
        let { isKnowMoreBtn, isCloseKnowMoreModal } = this.props;
        let { modalData, howItWorksData} = this.state
        return (
            <>
            {!isCloseKnowMoreModal && <Modal hideClose={true} toggle={this.props.handleKnowMoreBtn} hidden={!isKnowMoreBtn} >
                <FormatModal>
                    <div className="RewardCanWin">
                        <div className="RewardCanWin-Header">
                            <div className="RewardCanWin-Header-Heading">Rewards that you can win</div>
                            <button onClick={() => { this.props.closeKnowMoreModal(true) }} 
                                style={{background:"white", border:"none", outline:"none"}}>
                                <img src={modalCross} alt=""/>
                            </button>
                        </div>
                        <div className="RewardCanWin-Content">

                        {/* After special award is over : comment below div */}

                        {modalData.map((data, index)=>{
                            return (
                                <div className="RewardCanWin-Content-Item" style={{background:data.bgColor}} key={index}>
                                    <img src={data.image} alt="" />
                                    <div className="RewardCanWin-Content-Item-TextContent">
                                        <div className="RewardCanWin-Content-Item-TextContent-Row2">{data.row2}</div>
                                        <div className="RewardCanWin-Content-Item-TextContent-Row1">{data.row1}</div>
                                    </div>
                                </div>)
                        })}

                        {/* After special award is over : uncomment below div */}

                        {/* {modalData.slice(0,4).map((data, index)=>{
                            return (
                                <div className="RewardCanWin-Content-Item" style={{background:data.bgColor}} key={index}>
                                    <img src={data.image} alt="" />
                                    <div className="RewardCanWin-Content-Item-TextContent">
                                        <div className="RewardCanWin-Content-Item-TextContent-Row2">{data.row2}</div>
                                        <div className="RewardCanWin-Content-Item-TextContent-Row1">{data.row1}</div>
                                    </div>
                                </div>)
                        })} */}
                            
                        </div>
                        <div className="RewardCanWin-HowItWorks">
                            <div className="RewardCanWin-HowItWorks-Header">
                                <div className="RewardCanWin-HowItWorks-Header-left">How it works</div>
                                <div className="RewardCanWin-HowItWorks-Header-right" onClick={()=>{this.props.history.push("/TnC")}}>T&C*</div>
                            </div>
                            <div className="RewardCanWin-HowItWorks-Content">
                                {howItWorksData.map((data, index)=>{
                                    return <div className="RewardCanWin-HowItWorks-Content-Item" key={index}>
                                                <img src={data.image} alt="" />
                                                <div className="RewardCanWin-HowItWorks-Content-Item-Text">{data.text}</div>
                                            </div>
                                })}
                            </div>
                            <div className="RewardCanWin-HowItWorks-Footer">
                            *A successful referral corresponds to the referred teacher signing up and getting their app created by Classplus
                            </div>
                        </div>
                    </div>
                </FormatModal>
            </Modal>}
            </>
        );
    }
}
export default withRouter(RewardCanWin);
