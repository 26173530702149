import React, { Component } from "react";
import HomeImg from "../../images/Home/HomeImg.png"

class PartnerBanner extends Component {
    constructor() {
        super();
        this.state = {

        };
    }

    render() {
        return (
            <div className="PartnerBanner">
                <div className="PartnerBanner-TextContent">
                    <div className="PartnerBanner-TextContent-Top">This Christmas it’s our <span style={{ color: "#FFE1A1" }}>3rd birthday</span></div>
                    <div className="PartnerBanner-TextContent-Bottom">Let’s celebrate with <span style={{ color: "#FFE1A1" }}>Sourav Ganguly</span></div>
                </div>
                <img src={HomeImg} alt="" />
            </div>
        );
    }
}

export default PartnerBanner;
