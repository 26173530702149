import React, { Component } from "react";
import info from "../../images/MyRewards/info.png"
import CustomUser from "../../CustomUser/CustomUser"
import ReferalStatusModal from "../ModalComponents/ReferalStatusModal"
import ReferalHistoryItem from "./ReferalHistoryItem"

class ReferalHistory extends Component {
    constructor() {
        super();
        this.state = {
            rewardsData:[
                {name:"Kanika", num:"7219111790", status:"Pending"},
                {name:"Kanika", num:"7219111790", status:"Converted"},
                {name:"Kanika", num:"7219111790", status:"Pending"},
                {name:"Kanika", num:"7219111790", status:"Converted"},
                {name:"Kanika", num:"7219111790", status:"Verified"},
                {name:"Kanika", num:"7219111790", status:"Pending"},
                {name:"Kanika", num:"7219111790", status:"Verified"},
                {name:"Kanika", num:"7219111790", status:"Pending"},
            ],
            isInfo:false
        };
    }

    handleInfoBtn = () => {
        this.setState({
            isInfo: false,
        },()=>{this.props.isOverFlowSet()});
      };

    render() {
        let {rewardsData, isInfo} = this.state
        let {ReferListData} = this.props
        // console.log(ReferListData)
        return (
            <div className="ReferalHistory">
                <div className="ReferalHistory-Header">
                    <div className="ReferalHistory-Header-left">Referral History</div>
                    <button className="ReferalHistory-Header-right" onClick={()=>{this.setState({isInfo:true},()=>{this.props.isOverFlowSet()})}}>
                        <img src={info} alt="" />
                        <label>Status</label>
                    </button>
                </div>
                <div className="ReferalHistory-Contents">
                {ReferListData && ReferListData.length ? ReferListData.map((data, index)=>{
                    return <ReferalHistoryItem ReferListData={data} key={index} />
                }): null} 
                   
                </div>
                <ReferalStatusModal
                    isInfo={isInfo} 
                    handleInfoBtn={this.handleInfoBtn}
                 />
            </div>
        );
    }
}

export default ReferalHistory;
