import React, { Component } from "react";
import bluePlay from "../../images/Home/bluePlay.png";
import redPlay from "../../images/Home/redPlay.png";
import yellowPlay from "../../images/Home/yellowPlay.png";
import teacher1Img from "../../images/Home/teacher1.png";
import teacher2Img from "../../images/Home/teacher2.png";
import teacher3Img from "../../images/Home/teacher3.png";
import teacher4Img from "../../images/Home/teacher4Img.png";
import blueQuote from "../../images/Home/blueQuote.png";
import redQuote from "../../images/Home/redQuote.png";
import yellowQuote from "../../images/Home/yellowQuote.png";

class Carousel extends Component {
  constructor() {
    super();
    this.state = {
      data: [
        {
          image: teacher1Img,
          quote: blueQuote,
          name: "Mahesh Studies",
          text: "The app is very user friendly and helped my friends go online",
          play: bluePlay,
          bgColor: "#B8D7D2",
          url:"0IIAeNnfKag"
        },
        {
          image: teacher2Img,
          quote: yellowQuote,
          name: "Turning Point",
          text: "Classplus rewarded me with a TV for referring my friends",
          play: yellowPlay,
          bgColor: "#F9D896",
          url:"dXMr6b6q-Y4"
        },
        // {
        //   image: teacher3Img,
        //   quote: redQuote,
        //   name: "Meet Anurag Sir",
        //   text: "Featured twice in national ad campaign via partner program",
        //   play: redPlay,
        //   bgColor: "#FFCFCD",
        // },
        // {
        //   image: teacher4Img,
        //   quote: blueQuote,
        //   name: "Meet Anurag Sir",
        //   text: "Featured twice in national ad campaign via partner program",
        //   play: bluePlay,
        //   bgColor: "#B8D7D2",
        // },
      ],
    };
  }

  render() {
    let { data } = this.state;
    return (
      <div className="Carousel">
        <div className="Carousel-Heading">Partner Stories</div>
        <div className="Carousel-Text">
          Listen to what our existing partners have to say
        </div>
        <div className="Carousel-Content">
          {data.map((data, index) => {
            return (
              <div
                className="Carousel-Content-Item"
                key={index}
                style={{ backgroundColor: data.bgColor }}
                onClick={()=>{this.props.openVideo(data.url,data.name)}}
              >
                <div className="Carousel-Content-Item-TextContent">
                  <img
                    src={data.quote}
                    alt=""
                    className="Carousel-Content-Item-TextContent-Quotation"
                  />
                  <div className="Carousel-Content-Item-TextContent-Text">
                    {data.text}
                  </div>
                  <div className="Carousel-Content-Item-TextContent-Name">
                    {data.name}
                  </div>
                  <img
                    src={data.play}
                    alt=""
                    className="Carousel-Content-Item-TextContent-Play"
                  />
                </div>
                <img
                  src={data.image}
                  alt=""
                  className="Carousel-Content-Item-TeacherImg"
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default Carousel;
