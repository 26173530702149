import React, { Component } from "react";
import CustomUser from "../../CustomUser/CustomUser"
import YourRewardsModal from "../ModalComponents/YourRewardsModal"

class ReferalHistoryItem extends Component {
    constructor() {
        super();
        this.state = {
           isRewardsModal: false,

        };
    }

    handleRewardsModal = () => {
        this.setState({
            isRewardsModal: false,
        });
      };

    render() {
        let {isRewardsModal} = this.state
        let {data, ReferListData} = this.props
        // console.log(ReferListData)
        return (
            <>
                <div className="ReferalHistory-Contents-Item">
                    <div style={{display:"flex", alignItems:"center"}}>
                        <CustomUser username={ReferListData && ReferListData.name}/>
                        <div className="ReferalHistory-Contents-Item-TextContents">
                            <div className="ReferalHistory-Contents-Item-TextContents-Name">{ReferListData && ReferListData.name}</div>
                            <div className="ReferalHistory-Contents-Item-TextContents-Number">{ReferListData && ReferListData.contact}</div>
                        </div>
                    </div>
                    <button onClick={()=>{
                        this.setState({isRewardsModal: ReferListData && ReferListData.status === "Converted" ? true : ReferListData && ReferListData.status === "Verified" ? true : false })
                    }} 
                    style={ReferListData && ReferListData.status === "Converted" ? {background:"#45A145", color:"#fff"} : ReferListData && ReferListData.status === "Verified" ? {background:"#5B99F2", color:"#fff"} : {} } className="ReferalHistory-Contents-Item-Button">{ReferListData && ReferListData.status}</button>
                </div>
               
                 {isRewardsModal ? <YourRewardsModal
                 id={ReferListData && ReferListData.id}
                 isRewardsModal={isRewardsModal}
                 handleRewardsModal={this.handleRewardsModal}
                 />: null}
           </>
        );
    }
}

export default ReferalHistoryItem;
