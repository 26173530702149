import React, { Component } from "react";

class MyRewardDashboard extends Component {
    constructor() {
        super();
        this.state = {
            data:[
                {text:"Coins", value:"23000", bgColor:"#45A145"},
                {text:" Live hours", value:"175", bgColor:"#F4A80B"},
                {text:"Revenue", value:"₹20", bgColor:"#F08A70"},
            ]
        };
    }

    render() {
        let {data} = this.state
        let {DashboardData} = this.props
        return (
            <div className="MyRewardDashboard">
                <div className="MyRewardDashboard-Item"  style={{background:"#45A145"}}>
                    <div className="MyRewardDashboard-Item-Row1">Coins</div>
                    <div className="MyRewardDashboard-Item-Row2">{DashboardData && DashboardData.coins}</div>
                </div>

                <div className="MyRewardDashboard-Item"  style={{background:"#F4A80B"}}>
                    <div className="MyRewardDashboard-Item-Row1">Live hours</div>
                    <div className="MyRewardDashboard-Item-Row2">{DashboardData && DashboardData.liveHours}</div>
                </div>

                <div className="MyRewardDashboard-Item"  style={{background:"#F08A70"}}>
                    <div className="MyRewardDashboard-Item-Row1">Revenue</div>
                    <div className="MyRewardDashboard-Item-Row2">₹{DashboardData && DashboardData.revenue}</div>
                </div>
            </div>
        );
    }
}

export default MyRewardDashboard;
