import React, { Component } from "react";
import navLogo from "../../images/Home/navLogo.png"
import navSantaImg from "../../images/Home/navSantaImg.png"
import PartnerBanner from "./PartnerBanner"
import ReferWinReward from "./ReferWinReward"
import YoutubeVideo from "./YoutubeVideo"
import Carousel from "./Carousel"
import { withRouter } from "react-router-dom";
import ReferModal from "../ModalComponents/ReferModal"
import VideoModal from "../VideoModal";
class Home extends Component {
  constructor() {
    super();
    this.state = {
      isVideoModal:false,
      isReferModal:false,
      videoUrl:"",
      videoName:""
    };
  }

  handlleReferModal = ()=>{
    this.setState({isReferModal:false})
  }

  openVideo = (v,n) => {
    this.setState({isVideoModal:!this.state.isVideoModal,videoUrl:v,videoName:n})
  }

  render() {
    let {isReferModal, isVideoModal ,videoUrl, videoName} = this.state
    let {infoApiResponse} = this.props
    console.log(infoApiResponse)
    return (
      <div className="home">
        <div className="home-nav">
          <img src={navLogo} alt="" className="home-nav-logo" />
          <img src={navSantaImg} alt="" className="home-nav-santa" />
        </div>
        <PartnerBanner />
        <ReferWinReward />
        <YoutubeVideo />
        <Carousel openVideo={this.openVideo} />
        <div className="home-footer">
        {infoApiResponse.alreadyPartner ? 
          <>
            <button className="home-footer-viewrewards" onClick={()=>{this.props.history.push("/MyRewards")}}>View Rewards</button>
            <button className="home-footer-referfriend" onClick={()=>{this.setState({isReferModal:true})}}>Refer a friend</button>
        </>
        :
        <>
        <button className="home-footer-referfriendOnly" onClick={()=>{this.setState({isReferModal:true})}}>Refer a friend</button>
        </>}
         
        </div>
        <ReferModal
          isReferModal={isReferModal}
          handlleReferModal={this.handlleReferModal}
        />
        {isVideoModal? <VideoModal data={videoUrl} name={videoName} openVideo={this.openVideo} /> : ""}
      </div>
    );
  }
}

export default withRouter(Home);
