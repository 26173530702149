import React, { Component } from 'react';
import Modal from "../../UI/Modal/Modal";
import FormatModal from "../../UI/FormatModal/FormatModal";

class ReferalStatusModal extends Component {
    constructor() {
        super();
        this.state = {
            modalData:[
                {btnName:"Pending", text:"We have recieved your Referral and it is under verification process.", btnColor:"#fff", textColor:"#666666"},
                {btnName:"Verified", text:"The referral you made has been verified. It is being followed up by our executives.", btnColor:"#5B99F2", textColor:"#fff"},
                {btnName:"Converted", text:"The referral you made has successfully joined the Classplus family", btnColor:"#45A145", textColor:"#fff"},
            ]
        };
    }

    render() {
        let { isInfo } = this.props;
        let { modalData} = this.state
        return (
            <Modal toggle={this.props.handleInfoBtn} hidden={!isInfo} >
                <FormatModal>
                    <div className="ReferalStatusModal">
                        <div className="ReferalStatusModal-Header">
                            <div className="ReferalStatusModal-Header-Heading">Referral Status</div>
                        </div>
                        <div className="ReferalStatusModal-Content">
                            {modalData.map((data, index)=>{
                                return <div className="ReferalStatusModal-Content-Item" key={index}>
                                <div className="ReferalStatusModal-Content-Item-Button" style={{background:data.btnColor, color:data.textColor}}>{data.btnName}</div>
                                <div className="ReferalStatusModal-Content-Item-Text">{data.text}</div>
                            </div>
                            })}
                        </div>
                        <div className="ReferalStatusModal-Footer">
                            <button className="ReferalStatusModal-Footer-button" onClick={this.props.handleInfoBtn}>Got it!</button>
                        </div>
                    </div>
                </FormatModal>
            </Modal>

        );
    }
}
export default ReferalStatusModal;
