import React, { Component } from 'react';
import Modal from "../../UI/Modal/Modal";
import FormatModal from "../../UI/FormatModal/FormatModal";
import referModalIcon from "../../images/Modal/referModalIcon.png"
import indiaCode from "../../images/Modal/indiaCode.png"
import ReferSuccessModal from "./ReferSuccessModal"
import { baseUrl } from "../../config"

class ReferModal extends Component {
    constructor() {
        super();
        this.name = ''
        this.whatsAppUrl = ''
        this.state = {
           isReferSuccessModal:false,
           name:"",
           phone:"",
           city:"",
           nameValid: true,
           phoneValid: true,
           isDisable: true,
           whatsAppLink:""
        };
    }

    handleReferSuccessModal = ()=>{
        this.setState({isReferSuccessModal:false})
    }

    handleName = (e)=>{
        this.setState({name:e.target.value, nameValid:true}, ()=>{
            this.validation()
            // console.log(this.state.nameValid)
        })
    }

    handlePhone = (e)=>{
        let {phone} = this.state
        if(e.target.value.length <= 10){
            this.setState({phone:e.target.value, phoneValid:true},()=>{
                this.validation()
                // console.log(this.state.phoneValid)
            })
        }
    }

    handleNameValid = (e)=>{
        if(e.target.value.length >= 3){
            this.setState({nameValid:true}, ()=>{
                this.validation()
                // console.log(this.state.nameValid)
            })
        }
        else{
            this.setState({nameValid:false}, ()=>{
                this.validation()
                // console.log(this.state.nameValid)
            })
        }
    }

    handlePhoneValid = (e)=>{
        if(e.target.value.length === 10){
            this.setState({phoneValid:true}, ()=>{
                this.validation()
                // console.log(this.state.phoneValid)
            })
        }
        else{
            this.setState({phoneValid:false}, ()=>{
                this.validation()
                // console.log(this.state.phoneValid)
            })
        }
    }

    validation = ()=>{
        let {nameValid, phoneValid, isDisable} = this.state
        if(nameValid && phoneValid){
            this.setState({isDisable:false}, ()=>{
                // console.log("nameValid, phoneValid, isDisable=>", nameValid, phoneValid, isDisable)
            })
            
        }
    }

    getQuery() {
        let b = window.location.search
            .slice(1)
            .split("&")
            .map(qStr => qStr.split("="))
            .reduce((acc, inc) => {
                acc[inc[0]] = inc[1];
                return acc;
            }, {});
        // console.log(b)
        return b;
    }

    handleSubmit = ()=>{
        let {name, phone, city, nameValid, phoneValid} = this.state
        let body = {name:name, mobile:phone, city:city}
        this.name = name;

        fetch(`${baseUrl}/v2/partner/contact`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "x-access-token": this.getQuery()["token"] || "eyJhbGciOiJIUzM4NCIsInR5cCI6IkpXVCJ9.eyJpZCI6Mjg5OTk0LCJvcmdJZCI6MTcwLCJuYW1lIjoiR3VuamFuIiwiZW1haWwiOiJndW5qYW5AY2xhc3NwbHVzLmNvIiwibW9iaWxlIjoiOTE5NzExMTUzMDc5IiwidHlwZSI6MywiaWF0IjoxNjA4NjI4NjE4LCJleHAiOjE2MDkyMzM0MTh9.4prlPW0F37goSDfAK9rlCHQQLAQzATpamSoEBctzzCMgYvhlqLmTaJRtO-uBdunb"
            },
            body: JSON.stringify(body),
          })
            .then((res) => res.json())
            .then((data) => {
                console.log('dataaaaa', data)

              if(data.status === "failure"){
                console.log(data.status)
              }
              else
              {
                this.whatsAppUrl = data && data.data && data.data.link
                this.whatsAppUrl = btoa(this.whatsAppUrl)
                this.setState({isReferSuccessModal: true}, ()=>{
                    this.props.handlleReferModal()
                    this.setState({
                        name:"", phone:"", city:""
                    })
                })  
              }
            })
            .catch((err) => console.log(err.message));   

    }

    handleCity = (e)=>{
        this.setState({city:e.target.value})
    }

    render() {
        let { isReferModal } = this.props;
        let {isReferSuccessModal, name, phone, city, isDisable, nameValid, phoneValid} = this.state
        return (
            <>
            <Modal toggle={this.props.handlleReferModal} hidden={!isReferModal} >
                <FormatModal>
                    <div className="ReferModal">
                        <div className="ReferModal-Header">
                            <div className="ReferModal-Header-Heading">Please provide us a few details</div>
                        </div>
                        <div className="ReferModal-Content">
                            <div className="ReferModal-Content-Note">
                                <img src={referModalIcon} alt="" />
                                <div className="ReferModal-Content-Note-Text">This information will help us to contact your referral and add him/her to your partner program</div>
                            </div>
                            <div className="ReferModal-Content-InputFileld">
                                <div className="ReferModal-Content-InputFileld-Label">Referral Name</div>
                                <input type="text" className="ReferModal-Content-InputFileld-Input" placeholder="e.g. Harsh Srivastava, ABC Education Hub" value={name} onChange={this.handleName} onBlur={this.handleNameValid}/>
                            </div>
                            {!nameValid ? <div className="ReferModal-Error">Please enter valid name</div> : ""}
                            <div className="ReferModal-Content-InputFileld">
                                <div className="ReferModal-Content-InputFileld-Label">Referral Mobile Number</div>
                                <div className="ReferModal-Content-InputFileld-MobileContent">
                                    <div className="ReferModal-Content-InputFileld-MobileContent-India"><img src={indiaCode} alt="" /></div>
                                    <input type="number" className="ReferModal-Content-InputFileld-MobileContent-Input" placeholder="e.g. 9876543210" value={phone} onChange={this.handlePhone} onBlur={this.handlePhoneValid}/>
                                </div>
                            </div>
                            {!phoneValid ? <div className="ReferModal-Error">Please enter valid 10 digit mobile number</div> : ""}
                            <div className="ReferModal-Content-InputFileld">
                                <div className="ReferModal-Content-InputFileld-Label">Referral City (optional)</div>
                                <input type="text" className="ReferModal-Content-InputFileld-Input" placeholder="e.g. New Delhi" value={city} onChange={this.handleCity}/>
                            </div>
                        </div>
                        <div className="ReferModal-Footer">
                            <button disabled={isDisable} className="ReferModal-Footer-Btn" onClick={this.handleSubmit}>Submit</button>
                        </div>
                    </div>
                </FormatModal>
            </Modal>
            <ReferSuccessModal 
            isReferSuccessModal = {isReferSuccessModal}
            handleReferSuccessModal={this.handleReferSuccessModal}
            name={this.name}
            whatsAppUrl= {this.whatsAppUrl}
            />
            </>

        );
    }
}
export default ReferModal;
