import React, { Component } from "react";
import backArrow from "../../images/MyRewards/backArrow.png"
import MyRewardDashboard from "./MyRewardDashboard"
import MyRewardCarousel from "./MyRewardCarousel"
import ReferalHistory from "./ReferalHistory"
import { withRouter } from "react-router-dom";
import { baseUrl } from "../../config"

class MyRewards extends Component {
    constructor() {
        super();
        this.state = {
            myRewardApiResponse:{},
            isOverFlow:false
        };
    }

    componentDidMount(){
        window.scrollTo(0,0)
        this.getMyRewardApi();
    }

    getQuery() {
        let b = window.location.search
            .slice(1)
            .split("&")
            .map(qStr => qStr.split("="))
            .reduce((acc, inc) => {
                acc[inc[0]] = inc[1];
                return acc;
            }, {});
        // console.log(b)
        return b;
    }

    getMyRewardApi = ()=>{
        fetch(`${baseUrl}/v2/partner/rewards`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "x-access-token": this.getQuery()["token"] || "eyJhbGciOiJIUzM4NCIsInR5cCI6IkpXVCJ9.eyJpZCI6Mjg5OTk0LCJvcmdJZCI6MTcwLCJuYW1lIjoiR3VuamFuIiwiZW1haWwiOiJndW5qYW5AY2xhc3NwbHVzLmNvIiwibW9iaWxlIjoiOTE5NzExMTUzMDc5IiwidHlwZSI6MywiaWF0IjoxNjA4NjI4NjE4LCJleHAiOjE2MDkyMzM0MTh9.4prlPW0F37goSDfAK9rlCHQQLAQzATpamSoEBctzzCMgYvhlqLmTaJRtO-uBdunb"
            },
          })
            .then((res) => res.json())
            .then((res) => {
              this.setState({ myRewardApiResponse:res.data },()=>console.log(this.state.myRewardApiResponse));
            })
            .catch((err) => console.log(err.message)); 
    }

    isOverFlowSet = () => {
        this.setState({isOverFlow:!this.state.isOverFlow})
    }

    render() {
        let {myRewardApiResponse,isOverFlow} = this.state
        // console.log(myRewardApiResponse)
        return (
            <div style={isOverFlow?{overflow:"hidden"}:{}} className="MyRewards">
                <div className="MyRewards-Header">
                    <img src={backArrow} alt="" onClick={()=>{this.props.history.push("/")}} />
                    <div className="MyRewards-Header-Heading">My Rewards</div>
                </div>
                <MyRewardDashboard DashboardData={myRewardApiResponse && myRewardApiResponse.myRewards}/>
                <MyRewardCarousel  />
                <ReferalHistory isOverFlowSet={this.isOverFlowSet} ReferListData={myRewardApiResponse && myRewardApiResponse.referList} />
            </div>
        );
    }
}

export default withRouter(MyRewards);
